@font-face {
  font-family: 'defago-noto-sans-lao';
  src: url('./fonts/defago-noto-sans-lao.ttf');
  font-weight: normal;
  font-style: normal;
}
@import './vars.less';
@import './login.less';
* {
  font-family: 'defago-noto-sans-lao';
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after {
  border-bottom: 0;
}

.case_forgetting {
  font-size: 12px;
  > * {
    text-align: center;
  }
}
.box-shadow(@string: 0px 8px 20px 10px rgb(109 117 135 ,0.08)) {
  // -webkit-box-shadow: @string;
  // -moz-box-shadow: @string;
  box-shadow: @string;
}
.ant-card {
  box-shadow: 0px 8px 20px 10px rgba(109, 117, 135, 8%);
}
.ant-card.laoit-dev-card {
  .ant-card-head {
    border-bottom: 0;
    .ant-card-head-title {
      border-bottom: 1px solid @primary-color;
      flex: none;
      font-size: 26px;
    }
  }
}
.ant-card-bordered {
  border-radius: 4px solid #f0f0f0;
}

.summary-purchase,
.summary-payment {
  .ant-form-item {
    margin-bottom: 0;
  }
}

.laoitdev {
  .ant-modal-header {
    border-bottom: 0;
    .ant-modal-title {
      font-size: 24px;
      border-bottom: 2px solid @primary-color;
      display: inline-block;
    }
  }
  .ant-modal-footer {
    border-top: 0;
    text-align: left;
  }
}

.ant-upload.ant-upload-drag.drag-active {
  display: none;
  transition: display 0s, display 1s linear;
  + .ant-upload-list {
    .ant-upload-list-picture-card-container {
      width: 100%;
      height: auto;
      .ant-upload-list-item-actions {
        a:first-child {
          display: none;
        }
      }
    }
  }
}
