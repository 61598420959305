.login-form-forgot {
  float: right;
  color: red;
}
.avatar {
  text-align: center;
  margin-bottom: 20px;
}
.login-form-button {
  width: 100%;
}
.footer {
  background-color: transparent !important;
  color: #fff !important;
}
